import { createContext, useContext, useEffect, useState } from 'react';

import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

import { QueryObserverResult } from '@tanstack/react-query';

import { useAuth } from '@clerk/nextjs';

import { ApiResponse } from '@/@types/api-response';

import { useTradeInContext } from '@/context/use-trade-in-provider';
import { useUserAuth } from '@/context/user-auth-provider';

import type { Offer } from '@@/ItemAndGrading/entities/offer';
import { OFFER_STATUS, OFFER_TARGET } from '@@/Pricing/constants';
import { BuyProduct } from '@@/Pricing/entities/buy-product';
import { useBestBid } from '@@/Pricing/hooks/use-bid-service';
import { useOfferSocketIo } from '@@/Pricing/hooks/use-offer-socket-io';
import { BestBidResponse } from '@@/Pricing/services/bid-service';

import { STORAGE_KEYS, useLocalStorage } from '@/hooks/use-local-storage';

import { ROUTES } from '@/constants';

import { isCustomerTradeInRoute, isRouteAfterAuction } from '@/utils/routes-validators';

export type PricingContext = {
  bestBidData: BestBidResponse;
  setBestBidData: (data: BestBidResponse) => void;
  bestBidRefetch: () => Promise<QueryObserverResult<ApiResponse<BestBidResponse>, Error>>;
  isBestBidLoading?: boolean;
  isBestBidWithError?: boolean;
  isBestBidRefetching?: boolean;
  cleanUpPricing: () => void;
  buyProduct?: BuyProduct | null;
  setBuyProduct: (data: Partial<BuyProduct>) => void;
  offer: Offer | undefined;
  isOfferSuccess: boolean;
  originalPrice?: number;
  setOriginalPrice: (price: number) => void;
};

const pricingContext = createContext<PricingContext>({} as PricingContext);

export const PricingProvider: React.FCWC = ({ children }) => {
  const storage = useLocalStorage();
  const pathName = usePathname();
  const { push } = useRouter();

  const { interceptorId, isSignedIn } = useUserAuth();
  const { isLoaded } = useAuth();
  const { itemId, tradeInId } = useTradeInContext();
  const { offer, isOfferSuccess } = useOfferSocketIo({ tradeInId: tradeInId });

  const isAuthLoaded = isLoaded && isSignedIn && interceptorId !== null;
  const isCustomerTradeIn = isCustomerTradeInRoute(pathName);

  const bestBidResponse = useBestBid({
    retry: 2,
    itemId: itemId,
    buyProductPrice: storage?.getItem(STORAGE_KEYS.DOJI_BUY_NEW_PRODUCT)?.price ?? null,
    isCustomerTradeIn: isCustomerTradeIn,
    enabled: !!itemId && isAuthLoaded,
  });

  const [bestBidData, setBestBidData] = useState(bestBidResponse.bestBidData ?? ({} as BestBidResponse));
  const [buyProduct, setBuyProduct] = useState<BuyProduct | null>(
    storage?.getItem(STORAGE_KEYS.DOJI_BUY_NEW_PRODUCT) ?? null
  );
  const [originalPrice, setOriginalPrice] = useState<number | undefined>(
    storage?.getItem(STORAGE_KEYS.DOJI_ORIGINAL_PRICE)
  );

  useEffect(() => {
    if (bestBidResponse.isBestBidSuccess && bestBidResponse.bestBidData) {
      setBestBidData(bestBidResponse.bestBidData);
    }
  }, [bestBidResponse.isBestBidSuccess, bestBidResponse.bestBidData]);

  useEffect(() => {
    if (buyProduct) storage?.setItem(STORAGE_KEYS.DOJI_BUY_NEW_PRODUCT, buyProduct);
  }, [buyProduct]);

  useEffect(() => {
    if (originalPrice !== undefined) storage?.setItem(STORAGE_KEYS.DOJI_ORIGINAL_PRICE, originalPrice);
  }, [originalPrice]);

  useEffect(() => {
    if (!!offer?.targetRefId && offer?.target === OFFER_TARGET.DOJIZAP && !isRouteAfterAuction(pathName)) {
      switch (offer.status) {
        case OFFER_STATUS.ACTIVE:
          push(ROUTES.TRADE_IN_AUCTION);
          break;
        case OFFER_STATUS.ACCEPTED:
          push(ROUTES.TRADE_IN_AUCTION_SUCCESS);
          break;
        case OFFER_STATUS.EXPIRED:
          push(ROUTES.TRADE_IN_AUCTION_CANCEL);
          break;
        case OFFER_STATUS.CANCELED:
          push(ROUTES.TRADE_IN_AUCTION_CANCEL);
          break;
        default:
          break;
      }
    }
  }, [offer]);

  const cleanUpPricing = () => {
    setBestBidData({} as BestBidResponse);
    storage?.removeItem(STORAGE_KEYS.DOJI_BUY_NEW_PRODUCT);
    setOriginalPrice(undefined);
    storage?.removeItem(STORAGE_KEYS.DOJI_ORIGINAL_PRICE);
  };

  const value = {
    bestBidData,
    setBestBidData,
    isBestBidLoading: bestBidResponse.isBestBidLoading,
    isBestBidWithError: bestBidResponse.isBestBidError,
    isBestBidRefetching: bestBidResponse.isRefetching,
    bestBidRefetch: bestBidResponse.refetchBid,
    cleanUpPricing,
    buyProduct,
    setBuyProduct,
    offer,
    isOfferSuccess,
    originalPrice,
    setOriginalPrice,
  };

  return <pricingContext.Provider value={value}>{children}</pricingContext.Provider>;
};

export function usePricingContext() {
  return useContext(pricingContext);
}
