export enum OFFER_TARGET {
  BIDDER = 'BIDDER',
  DOJIZAP = 'DOJIZAP',
}

export enum OFFER_STATUS {
  ACTIVE = 'ACTIVE',
  ACCEPTED = 'ACCEPTED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
}
