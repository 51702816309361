import { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { useQuery } from '@tanstack/react-query';

import { useAuth } from '@clerk/nextjs';

import { useUserAuth } from '@/context/user-auth-provider';

import { useVerifyPermission } from '@@/Authentication/hooks';
import { Item } from '@@/ItemAndGrading/entities/item';
import { useFindItem } from '@@/ItemAndGrading/hooks/use-item-service';
import { TradeIn } from '@@/TradeIn/entities/trade-in';
import TradeInService from '@@/TradeIn/services/trade-in-service';

import { STORAGE_KEYS, useLocalStorage } from '@/hooks/use-local-storage';

import { fetchKeys } from '@/constants/fetch-keys';

import { isEmptyOrNil } from '@/utils';
import { isCustomerTradeInRoute, isPublicRoute } from '@/utils/routes-validators';

import FullScreenSpinner from '@/components/full-screen-spinner';

export type TradeInContext = {
  tradeInId?: string;
  tradeInData: TradeIn;
  setTradeInData: (data: TradeIn) => void;
  refetchTradeIn: () => void;

  itemId: string;
  itemData: Item;
  setItemData: (data: Item) => void;
  refetchItem: () => void;

  customerRefId: string;
  setCustomerRefId: (refId: string) => void;

  modelId: string;
  productName: string;
  cleanUp: () => void;
};

const tradeInContext = createContext<TradeInContext>({} as TradeInContext);

export const TradeInProvider: React.FCWC = ({ children }) => {
  const storage = useLocalStorage();
  const { interceptorId, isSignedIn } = useUserAuth();
  const { isLoaded } = useAuth();
  const { query, pathname } = useRouter();
  const { isOnlyAdmin } = useVerifyPermission();

  const isAuthLoaded = isLoaded && isSignedIn && interceptorId !== null;

  const isCustomerTradeIn = isCustomerTradeInRoute(pathname);
  const isAuthedRoute = !isPublicRoute(pathname) && !isCustomerTradeIn;

  const tradeInId = storage?.hasItem(STORAGE_KEYS.DOJI_TRADE_IN_ID)
    ? storage?.getItem(STORAGE_KEYS.DOJI_TRADE_IN_ID)
    : '';

  const itemId = storage?.hasItem(STORAGE_KEYS.DOJI_ITEM_ID) && storage?.getItem(STORAGE_KEYS.DOJI_ITEM_ID);
  const storageModelId = storage?.hasItem(STORAGE_KEYS.DOJI_MODEL_ID) && storage?.getItem(STORAGE_KEYS.DOJI_MODEL_ID);
  const storageCustomerRefId = storage?.hasItem(STORAGE_KEYS.DOJI_CUSTOMER_REF_ID)
    ? storage?.getItem(STORAGE_KEYS.DOJI_CUSTOMER_REF_ID)
    : query?.refId;

  const [customerRefId, setCustomerRefId] = useState(storageCustomerRefId ?? undefined);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const tradeInResponse = useQuery({
    queryKey: [fetchKeys.tradeIn.providerFind, tradeInId],
    queryFn: () => (isCustomerTradeIn ? TradeInService.findByIdSession(tradeInId) : TradeInService.findById(tradeInId)),
    enabled: !!tradeInId && isAuthLoaded,
  });

  const itemResponse = useFindItem({
    itemId: itemId,
    enabled: !!itemId && isAuthLoaded,
    isCustomer: isCustomerTradeIn,
  });

  const [tradeInData, setTradeInData] = useState<TradeIn>(tradeInResponse?.data?.response as TradeIn);
  const [itemData, setItemData] = useState<Item>(itemResponse?.itemData as Item);

  useEffect(() => {
    if (customerRefId) storage?.setItem(STORAGE_KEYS.DOJI_CUSTOMER_REF_ID, customerRefId);
  }, [customerRefId]);

  useEffect(() => {
    if (itemData || itemResponse?.itemData) {
      const item = itemData || itemResponse?.itemData;

      if (isEmptyOrNil(item)) return;

      setItemData(item);
      storage?.setItem(STORAGE_KEYS.DOJI_ITEM_ID, item.id);

      if (item?.product?.model?.fullModelName)
        storage?.setItem(STORAGE_KEYS.DOJI_PRODUCT_NAME, item.product.model.fullModelName);
    }
  }, [itemResponse?.itemData, itemData]);

  useEffect(() => {
    if (!isEmptyOrNil(tradeInData) || !isEmptyOrNil(tradeInResponse?.data?.response)) {
      const tradeIn = tradeInData || tradeInResponse?.data?.response;

      setTradeInData(tradeIn);
      storage?.setItem(STORAGE_KEYS.DOJI_TRADE_IN_ID, tradeIn.id);
      storage?.setItem(STORAGE_KEYS.DOJI_ITEM_ID, tradeIn.itemId);
    }
  }, [tradeInData, tradeInResponse?.data, tradeInResponse?.isFetching, tradeInId]);

  useEffect(() => {
    if (isEmptyOrNil(tradeInId)) setIsDataLoaded(true);
    else if (!isEmptyOrNil(tradeInData) && !isEmptyOrNil(itemData)) {
      setIsDataLoaded(true);
    }
  }, [tradeInId, tradeInData, itemData]);

  const cleanUp = () => {
    setItemData({} as Item);
    setTradeInData({} as TradeIn);
    storage?.removeItem(STORAGE_KEYS.DOJI_TRADE_IN_ID);
    storage?.removeItem(STORAGE_KEYS.DOJI_ITEM_ID);
    storage?.removeItem(STORAGE_KEYS.DOJI_MODEL_ID);
    storage?.removeItem(STORAGE_KEYS.DOJI_SURVEY_ANSWERS);
    storage?.removeItem(STORAGE_KEYS.DOJI_CUSTOMER_FORMS);
    storage?.removeItem(STORAGE_KEYS.DOJI_PRODUCT_NAME);
    storage?.removeItem(STORAGE_KEYS.DOJI_TRADE_IN_SESSION);
  };

  const value = {
    tradeInData,
    setTradeInData,
    tradeInId: tradeInData?.id ?? tradeInId,
    refetchTradeIn: tradeInResponse?.refetch,
    itemData,
    setItemData,
    refetchItem: itemResponse?.refetchItem,
    modelId: storageModelId,
    cleanUp,
    customerRefId,
    setCustomerRefId,
    itemId: itemData?.id || tradeInData?.itemId || itemId,
    productName:
      itemData?.product?.model?.fullModelName ?? storage?.getItem(STORAGE_KEYS.DOJI_PRODUCT_NAME) ?? query?.product,
  };

  if (((!isAuthLoaded && isAuthedRoute) || (isAuthedRoute && !isDataLoaded)) && !isOnlyAdmin)
    return <FullScreenSpinner />;

  return <tradeInContext.Provider value={value}>{children}</tradeInContext.Provider>;
};

export function useTradeInContext() {
  return useContext(tradeInContext);
}
