import { ApiResponse } from '@/@types/api-response';

import { BoostBid } from '@@/Pricing/entities/boost-bid';

import { tradeInApi } from '@/config/api';

export type BestBidResponse = {
  bidderId: string;
  storePrice: number;
  newProductPrice?: number | null;
  boosts?: BoostBid[];
  requireNewProductPrice?: boolean;
};

export type RequireNewProductPriceResponse = {
  requiresNewProductPrice: boolean;
};

export default class BidService {
  static async checkSponsorNewProductPrice(newProductId: string) {
    const { data } = await tradeInApi.post<ApiResponse<RequireNewProductPriceResponse>>(
      `/v1/secure/bids/requires-new-product-price`,
      {
        newProductId,
      }
    );

    return data;
  }

  static async getBestBid(itemId: string, newProductPrice?: number, newProductId?: string) {
    const { data } = await tradeInApi.get<ApiResponse<BestBidResponse>>(`/v1/secure/items/${itemId}/bids/best`, {
      params: {
        newProductPrice,
        newProductId,
      },
    });

    return data;
  }

  static async getSessionBestBid(itemId: string) {
    const { data } = await tradeInApi.get<ApiResponse<BestBidResponse>>(`/v1/session/items/${itemId}/bids/best`);

    return data;
  }
}
