import { NextRouter, useRouter } from 'next/router';

import { useUserAuth } from '@/context/user-auth-provider';

import { BUSINESS_SETUP_FLOW_PARAM } from '@@/StoreAndStoreOperator/entities/store';

import { useStoreConfig } from '@/hooks/use-store-config';

import { ROUTES } from '@/constants';
import { routesRestartWithoutGiveUp } from '@/constants/restart-routes';

import Typography from '@/components/typography';

import { DojiIcon } from './assets';
import MenuHeader from './menu-header';

export const handleRedirectTradeIn = (router: NextRouter, path: string, skipGiveUp?: boolean) => {
  const currentPath = router.pathname;
  const isRestartRoute = routesRestartWithoutGiveUp.some((route) => currentPath === '/' || currentPath.includes(route));

  if (isRestartRoute || skipGiveUp) {
    router.push(path, undefined, { shallow: true });
    return;
  }

  router.replace(`${ROUTES.TRADE_IN_GIVE_UP}?redirectTo=${path}`, undefined, { shallow: true });
};

const TopHeaderOperatorInfos = () => {
  const router = useRouter();
  const { hasFlowEnable } = useStoreConfig();
  const { logout, userData, storeData, isSignedIn } = useUserAuth();

  const hasCompleteName = userData?.firstName && userData?.lastName;

  const fullName = hasCompleteName
    ? `${userData.firstName} ${userData.lastName}`
    : isSignedIn
      ? 'Reconnecting...'
      : 'Loading...';

  return (
    <div
      className="border-b border-b-neutral-5 py-1 md:py-3"
      style={{ boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.10)' }}>
      <div className="container flex w-full items-center justify-between gap-4 px-4 md:mx-auto md:max-w-xl">
        <DojiIcon className="shrink-0" />

        <div className="grid grow md:flex md:grow-0 md:items-center md:gap-1">
          <Typography.Span variant="small-body" className="block truncate">
            {fullName}
          </Typography.Span>

          <Typography.Span variant="small-body" className="hidden text-on-surface-light-medium md:block">
            {' | '}
          </Typography.Span>

          <Typography.Span variant="small-body" className="block truncate text-on-surface-light-medium">
            {storeData?.name}
          </Typography.Span>
        </div>

        <MenuHeader
          logout={logout}
          router={router}
          store={storeData}
          shouldSkipGiveUp={!hasFlowEnable(BUSINESS_SETUP_FLOW_PARAM.FEEDBACK)}
        />
      </div>
    </div>
  );
};

export default TopHeaderOperatorInfos;
