import { IntlShape, useIntl } from 'react-intl';

import { useUserAuth } from '@/context/user-auth-provider/index';

import { BUSINESS_SETUP_FLOW_PARAM, BussinessSetup } from '@@/StoreAndStoreOperator/entities/store';

type FormatMessageValues = Parameters<IntlShape['formatMessage']>[1];

export const useStoreConfig = () => {
  const { storeData } = useUserAuth();
  const { formatMessage } = useIntl();

  const getParameterValue = (key: string, defaultValue?: string) => {
    const parameters = storeData?.pagesParameters;
    if (!parameters) return defaultValue;

    return parameters[key] ?? defaultValue;
  };

  const getParameterOrIntlMessage = (key: string, defaultValues?: FormatMessageValues) => {
    const parameters = storeData?.pagesParameters;
    if (!parameters) return formatMessage({ id: key });

    return parameters[key] ?? formatMessage({ id: key }, {}, defaultValues);
  };

  /**
   * Check if the retailer has business setup flow enabled.
   * Returns **true** if the parameter is null.
   * */
  const hasFlowEnable = (key: BUSINESS_SETUP_FLOW_PARAM): boolean => {
    const parameters = storeData?.businessSetup;
    if (!parameters || !parameters[key]) return true;

    return parameters[key].enable;
  };

  const getBussinessSetup = <K extends BUSINESS_SETUP_FLOW_PARAM>(key: K): BussinessSetup[K] | undefined => {
    const parameters = storeData?.businessSetup;
    if (!parameters) return undefined;

    return parameters[key];
  };

  return {
    getParameterValue,
    getParameterOrIntlMessage,
    hasFlowEnable,
    getBussinessSetup,
  };
};
