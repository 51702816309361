import type { BoostBid } from '@@/Pricing/entities/boost-bid';
import { BestBidResponse } from '@@/Pricing/services/bid-service';

export * from './format-currency';

export const getHighestStoreBoostPrice = (bidPrice?: BestBidResponse) =>
  bidPrice && bidPrice?.boosts && bidPrice.boosts.length > 0
    ? bidPrice.storePrice + Math.max(...bidPrice.boosts.map((boost) => boost.price ?? 0))
    : null;

export const getStoreBoostedPrice = (storePrice: number, selectedBoost: string, boosts?: BoostBid[]) => {
  if (!storePrice || !selectedBoost) return null;

  const boost = getSelectedBoost(selectedBoost, boosts);
  const storeBoostedPrice = storePrice + (boost?.price ?? 0);
  const roundedStoreBoostedPrice = Number(storeBoostedPrice.toFixed(2));

  return roundedStoreBoostedPrice;
};

export const getSelectedBoost = (selectedBoost: string, boosts?: BoostBid[]) =>
  boosts && selectedBoost ? boosts.find((boost) => boost.sponsorshipId === selectedBoost) : undefined;
