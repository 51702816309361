import { useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import { includes } from 'ramda';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import { useUserAuth } from '@/context/user-auth-provider';

import TopHeaderOperatorInfos from '@@/StoreAndStoreOperator/components/top-header-operator-infos';

import { ROUTES } from '@/constants';

import BannerStoreTest from '@/components/banner-store-test';
import Footer from '@/components/footer';

type Props = {
  children: React.ReactNode;
  className?: string;
  withoutHeader?: boolean;
  withoutFooter?: boolean;
  withoutStoreTest?: boolean;
  disableCenterDesk?: boolean;
  skipUserAuthCheck?: boolean;
  additionalHeightOffset?: {
    base: number;
    md: number;
  };
  classNameWrapperChildren?: string;
};

const container = tv({
  base: 'w-full h-full pt-3 pb-6',
  variants: {
    type: {
      center: 'md:flex md:flex-col md:items-center md:justify-center',
    },
  },
});

const Container = ({
  children,
  className,
  withoutHeader = false,
  withoutFooter = false,
  withoutStoreTest = false,
  disableCenterDesk = false,
  skipUserAuthCheck = false,
  additionalHeightOffset,
  classNameWrapperChildren,
}: Props) => {
  const { storeData, isSignedIn } = useUserAuth();
  const { push, pathname } = useRouter();

  const [isClient, setIsClient] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number>(0);

  useEffect(() => {
    if (window) setWindowWidth(window.innerWidth);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const shouldRedirectNotAuthed = useMemo(
    () =>
      !skipUserAuthCheck &&
      !storeData &&
      !includes(pathname, [ROUTES.OPERATOR_PORTAL, ROUTES.LOGIN, ROUTES.ONLY_ADMIN]),
    [storeData, pathname, skipUserAuthCheck]
  );

  const shouldRenderHeader = !withoutHeader && isClient && isSignedIn;

  useEffect(() => {
    setIsClient(true);

    if (shouldRedirectNotAuthed) push(ROUTES.OPERATOR_PORTAL);
  }, [storeData]);

  const calculateHeightShift = (breakpoint: 'base' | 'md') => {
    const headerHeight = { base: 41, md: 49 };
    const footerHeight = { base: 46, md: 46 };
    const testHeight = { base: 28, md: 28 };
    const bannerHeight = additionalHeightOffset;

    let heightShift = 0;

    if (!withoutHeader) heightShift += headerHeight[breakpoint];
    if (!withoutFooter) heightShift += footerHeight[breakpoint];
    if (!withoutStoreTest && storeData?.isTest) heightShift += testHeight[breakpoint];
    if (bannerHeight) heightShift += bannerHeight[breakpoint];

    return heightShift;
  };

  return (
    <>
      {shouldRenderHeader && <TopHeaderOperatorInfos />}

      <div className={twMerge('container box-border w-full px-4 md:mx-auto md:max-w-xl', className)}>
        <div
          style={{ minHeight: `calc(100vh - ${calculateHeightShift(windowWidth > 767 ? 'md' : 'base')}px)` }}
          className={container({
            type: disableCenterDesk ? undefined : 'center',
          })}>
          <div
            className={twMerge('h-full w-full', !withoutHeader ? 'py-2' : 'pt-10 md:pt-0', classNameWrapperChildren)}>
            {children}
          </div>
        </div>
      </div>

      {!withoutFooter && <Footer />}
      {!withoutStoreTest && storeData?.isTest && <BannerStoreTest />}
    </>
  );
};

export default Container;
