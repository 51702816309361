import { Membership } from '@@/Authentication/entities/membership';

import type { Retailer } from './retailer';

export type Store = {
  id: string;
  orgId: string;
  registrationNumber: string;
  functionalGradingRequired: boolean;
  priceFactor: number;
  name: string;
  email: string;
  acceptBoost: boolean;
  logoUrl?: string;
  customerTradeInEnabled: boolean;
  isTest?: boolean;
  allowAuctions?: boolean;
  membership?: Membership;
  retailer: Retailer;
  pagesParameters?: PageParameters | null;
  businessSetup?: BussinessSetup | null;
};

export type StorePublic = {
  id: string;
  name: string;
  acceptBoost: boolean;
  customerTradeInEnabled: boolean;
  logoUrl?: string;
};

export enum BUSINESS_SETUP_TARGET {
  RETAILER,
  STORE,
}

type PageParameters = {
  [key: string]: string;
};

type ParameterSetup = {
  enable: boolean;
};

export enum IMEI_CHECK_PROVIDER {
  IMEI_CHECK,
}

export enum BUSINESS_SETUP_FLOW_PARAM {
  IMEI_CHECK = 'imeiCheck',
  FEEDBACK = 'feedback',
  CUSTOMER_FORM = 'customerForm',
}

export type BussinessSetup = {
  targetId: string;
  target: BUSINESS_SETUP_TARGET;
  imeiCheck?: ParameterSetup & {
    provider: IMEI_CHECK_PROVIDER;
  };
  feedback?: ParameterSetup;
  customerForm?: ParameterSetup & {
    pixKey: boolean;
  };
};
